import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';

import './testimonials.css';

import { TVT_FB_URL, TVT_GOOGLE_URL } from '../constants';

import SEO from '../components/SEO';

import TextBlock from '../components/TextBlock';
import SocialReview from '../components/SocialReview/SocialReview';

import { UiButton } from '../components/CTAButton/CTAButton';
import CtaBlock from '../components/CtaBlock';
import { withQuoteWizard } from '../components/withQuoteWizard';

const styles = theme =>
  createStyles({
    root: {},
    wrapSocial: {
      backgroundColor: '#ECBAAB',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        padding: 40,
      },
    }
  });

class Testimonials extends React.Component {
  render() {
    const { classes, toggleQuoteComp, children } = this.props;

    return (
      <React.Fragment>
        <SEO
          title="Testimonials on Twin View Turf - Producer of Australia’s Best Lawns"
          keywords={[
            `Turf`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
          ]}
        />
        <TextBlock text="See what our customers think" />
        <Grid container justify="center" alignItems="stretch" className={classes.wrapSocial}>
          <Grid item>
            <SocialReview platform="google" source={TVT_GOOGLE_URL} />
          </Grid>
          <Grid item>
            <SocialReview platform="facebook" source={TVT_FB_URL} />
          </Grid>
        </Grid>
        <CtaBlock title="Want to be happy too?">
          <UiButton size="large" btnType="primary" onClick={toggleQuoteComp}>Get quote</UiButton>
        </CtaBlock>
        {children}
      </React.Fragment>
    );
  }
}

export default withQuoteWizard(withStyles(styles, { withTheme: true })(Testimonials));
